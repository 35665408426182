@font-face {
  font-family: 'brokenRobotFont';
  src: local('brokenRobotFont'), url("./assets/BRBB_Typeface.otf") format('opentype');
}

* {
  margin:0;
}

html {
  margin: 0;
}

body {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  font-family: brokenRobotFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171432 !important;
  background: #e1449d;
  background-image: url('./assets/leaderboard_background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  color: #e1449d;
}

.MuiButtonBase-root {
  border-radius: 0 !important;
  -webkit-transform: translateZ(0)
}

.MuiToggleButtonGroup-root {
  border-radius: 0 !important;
}

.MuiCircularProgress-colorPrimary {
  color: black !important;
}


.LeaderboardNavigation.MuiToggleButton-root:hover {
  transform: scale(1.07);
  color: #e1449d !important;
  /*transition: 0.05s;*/
}

.LeaderboardNavigation.MuiToggleButton-root.Mui-selected {
  background-color: black !important;
  color: #e1449d !important;
  background: 0;
  text-decoration: underline;
  /*border-color: #000 !important;*/
}

.LeaderboardNavigation.MuiToggleButton-root {
  border: 0;
  transition: .1s ease-in-out;
}

.ApproveButton {
  transition: transform .1s ease-in-out !important;
}

.ApproveButton[disabled] {
  background-color: black !important;
}

.ApproveButton:hover {
  transform: scale(1.07);
  background-color: #e1449d !important;
}

.ApproveButton:active {
  transform: scale(1);
  background-color: #e1449d !important;
}

.WalletConnectButton {
  transition: transform .075s ease-in-out !important;
}

.WalletConnectButton:hover {
  transform: scale(1.05);
  /*background-color: #e1449d !important;*/
}

.WalletConnectButton:active {
  transform: scale(1);
  /*background-color: #e1449d !important;*/
}

.hoverEffect:hover {
  transform: scale(1.1) !important;
  color: #e1449d !important;
  /*transition: transform .1s ease-in-out !important;*/
  /*font-size: 70px;*/
}

.hoverEffect {
  border: 0;
  transition: transform .1s ease-in-out !important;
}

.hoverEffectColor:hover {
  /*transform: scale(1.1) !important;*/
  color: #e1449d !important;
  /*transition: transform .1s ease-in-out !important;*/
  /*font-size: 70px;*/
}

.hoverEffectColor {
  border: 0;
  transition: color .1s ease-in-out !important;
}

.hoverEffectVisible:hover {
  /*transform: scale(1.1) !important;*/
  color: rgb(112, 95, 235) !important;
  /*transition: transform .1s ease-in-out !important;*/
  /*font-size: 70px;*/
  /*visibility: visible;*/
}

.hoverEffectVisible {
  border: 0;
  color: rgb(112, 95, 235);
  transition: color .1s ease-in-out !important;
}

button.MuiToggleButton-root.Mui-selected {
  background-color: #e1449d !important;
  color: black !important;
  background: 0;
  /*border-color: #000 !important;*/
}

button.MuiToggleButton-root {
  border: 0;
}

button.MuiToggleButton-root:hover {
  transform: scale(1.07);
  /*transition: 0.05s;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container2 {
  position: relative;
  text-align: center;
  color: white;
  display: inline-block;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  white-space: nowrap;
  color: black;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

image-stack::after {
   content: ' ' !important;
   display: table !important;
   clear: both !important;
   width: 250px;
}

.image-stack__item--top {
  float: left;
  width: 66%;
  margin-right: -100%;
  padding-top: 15%;
  position: relative;
  z-index: 1;
}

.parent {
  position: absolute;
  background: white;
  width: 200px;
  height: 200px;
  display: block;
  margin-top: -200px;
  /*justify-content: center;*/
  /*align-items: center;*/
  z-index: 10;
  opacity: 0;
}

.parent[data-isopen="true"] {
  position: absolute;
  display: block;
  /*width: 400px;*/
  /*height: 200px;*/
  width: 220px;
  height: auto;
  /*top: -10px;*/
  margin-top: -210px;
  margin-left: -10px;
  display: block;
  /*padding: 20px;*/
  z-index: 30;
  opacity: 1;
  filter: drop-shadow(0 0 0.75rem black);
}

.child {
  display: block;
  width: 40px;
  height: 40px;
  background: #f107a3;
  border-radius: 50%;
}